import { ref, computed, markRaw } from 'vue'

// Cells
import Status from '../components/Grid/Cell/PreCheckListPage/Status.vue'
import StatusHeader from '../components/Grid/Cell/PreCheckListPage/StatusHeader.vue'
import Operation from '../components/Grid/Cell/PreCheckListPage/Operation.vue'
import OperationHeader from '../components/Grid/Cell/SitePage/OperationHeader.vue'
import EnableTime from '../components/Grid/Cell/PreCheckListPage/EnableTime.vue'
import EnableTimeHeader from '../components/Grid/Cell/PreCheckListPage/EnableTimeHeader.vue'
import OSAndSourceName from '../components/Grid/Cell/PreCheckListPage/OSAndSourceName.vue'
import ApplyRules from '../components/Grid/Cell/PreCheckListPage/ApplyRules.vue'
import ApplyScope from '../components/Grid/Cell/PreCheckListPage/ApplyScope.vue'
import DocumentLink from '../components/Grid/Cell/PreCheckListPage/DocumentLink.vue'

// 區域列表中使用 Kendo Grid
const useGridInSite = () => {
  // grid 資料
  const dataItems = ref([])
  // 分頁用
  const pageable = {
    buttonCount: 1,
    pageSizes: [16, 32, 128, 255],
    type: 'input',
    info: false
  }
  const skip = ref(0)
  const take = ref(16)

  // Header 欄位
  const columns = ref([
    {
      field: 'Name',
      title: '名稱',
      width: '180px'
    },
    {
      field: 'Status',
      title: '狀態',
      width: '60px',
      cell: markRaw(Status),
      headerCell: markRaw(StatusHeader)
    },
    {
      field: 'Operation',
      title: '操作',
      width: '117px',
      cell: markRaw(Operation),
      headerCell: markRaw(OperationHeader),
      locked: true
    },
    {
      field: 'RulesSetting',
      title: '規則設定',
      width: '220px'
    },
    {
      field: 'EnableTime',
      title: '啟用時間',
      width: '145px',
      cell: markRaw(EnableTime),
      headerCell: markRaw(EnableTimeHeader)
    },
    {
      field: 'OSAndSourceName',
      title: '系統 / 外部資料名稱',
      width: '180px',
      cell: markRaw(OSAndSourceName),
      headerCell: markRaw(EnableTimeHeader)
    },
    {
      field: 'ApplyScope',
      title: '套用範圍',
      width: '120px',
      cell: markRaw(ApplyScope),
      headerCell: markRaw(EnableTimeHeader)
    },
    {
      field: 'ApplyRules',
      title: '套用設備條件',
      width: '200px',
      cell: markRaw(ApplyRules)
    },
    {
      field: 'DocumentLink',
      title: '說明文件連結',
      width: '120px',
      cell: markRaw(DocumentLink)
    }
  ])
  // 手動分頁
  const result = computed(() => {
    const dataItemsWithPage = dataItems.value.slice(
      skip.value,
      take.value + skip.value
    )
    return dataItemsWithPage
  })
  // 總資料數
  const total = computed(() => {
    return dataItems.value ? dataItems.value.length : 0
  })

  // 生成假資料
  const createRandomData = count => {
    const name = ['test', '防毒更新頻率', '防毒 & Hotfix']
    const status = [true, false]
    const rulesSetting = [
      '防毒版本 小於等於 3',
      '防毒版本 大於等於 12\n必須安裝的 Hotfix 包含 KB12'
    ]

    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        Name: name[Math.floor(Math.random() * name.length)],
        Status: status[Math.floor(Math.random() * status.length)],
        Operation: { isDeletable: true },
        RulesSetting:
          rulesSetting[Math.floor(Math.random() * rulesSetting.length)],
        EnableTime: '2021/01/20 00:00',
        OSAndSourceName: 'Trend',
        ApplyScope: {
          site: ['San Jose Office'],
          network: ['R&D Department', '業務課', '192.168.186.X'],
          ip: ['DF-OA-A3F']
        },
        ApplyRules: '作業系統 == Unknown',
        DocumentLink: ''
      }))
  }

  const pageChangeHandler = e => {
    skip.value = e.page.skip
    take.value = e.page.take
  }

  return {
    columns,
    dataItems,
    pageable,
    skip,
    take,
    result,
    total,
    createRandomData,
    pageChangeHandler
  }
}

export default useGridInSite
