
import { defineComponent, markRaw, ref } from 'vue'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import { Input } from '@progress/kendo-vue-inputs'
// import BaseInput from '@/components/Input/BaseInput.vue'
import { DateTimePicker } from '@progress/kendo-vue-dateinputs'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import Lable from '@/components/Lable/Lable.vue'
import '@progress/kendo-ui/js/kendo.treeview'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'
import '@progress/kendo-ui/js/kendo.data'

import {
  treeViewData,
  treeViewSchema,
  keyWithOptions,
  initialFilterUnitList
} from '@/mock/data/newPreCheck'

export default defineComponent({
  components: {
    DropDownList,
    KendoInput: Input,
    // BaseInput,
    DateTimePicker,
    FeildInputGroup,
    FilterWrapper,
    FilterUnitDeletable,
    FilterUnitAdd,
    Lable,
    TreeView
  },
  setup() {
    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    const submitForm = () => {
      // FIXME:
    }

    // TreeView 相關
    const template =
      '# for (i = 0; i < item.icons.length; i++ ) { #' +
      '<span class="tree-icon ' +
      '#: item.icons[i] #' +
      '"></span>' +
      '# } #' +
      '#: item.name #' +
      '<span class="child-count">' +
      '#: Number(item.count).toLocaleString() #' + // 1000 -> "1,000"
      '</span>'

    const localDataSource = markRaw(
      // eslint-disable-next-line
        new kendo.data.HierarchicalDataSource({
        data: treeViewData,
        schema: treeViewSchema
      })
    )
    // treeview template refs
    const treeViewRef = ref(null)

    return {
      // TreeView 相關
      template,
      localDataSource,
      treeViewRef,
      // 篩選相關
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      submitForm
    }
  }
})
