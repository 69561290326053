// 新增符規標準

// TreeView 相關
const treeViewData = [
  {
    id: 0,
    name: '全部',
    isParent: true,
    icons: [],
    count: 2392,
    expanded: true,
    childrens: [
      {
        id: 1,
        name: 'Kevin Office',
        isParent: false,
        icons: ['tree-i-site'],
        count: 1340
      },
      {
        id: 2,
        name: 'Taipei Office',
        isParent: true,
        icons: ['tree-i-site'],
        count: 1020,
        expanded: true,
        childrens: [
          {
            id: 3,
            name: 'R&D Department',
            isParent: true,
            icons: ['tree-i-network'],
            count: 1020,
            checked: true,
            childrens: [
              {
                id: 4,
                name: '192.168.67.X',
                icons: ['tree-i-ip'],
                count: 43
              }
            ]
          },
          {
            id: 5,
            name: 'R&D Department',
            isParent: true,
            icons: ['tree-i-network'],
            count: 1020,
            expanded: true,
            checked: true,
            childrens: [
              {
                id: 6,
                name: '192.168.67.X',
                icons: ['tree-i-ip'],
                count: 40
              }
            ]
          },
          {
            id: 7,
            name: 'Test',
            isParent: false,
            icons: ['tree-i-network'],
            count: 32
          }
        ]
      }
    ]
  }
]

const treeViewSchema = {
  model: {
    id: 'id',
    hasChildren: 'isParent',
    children: 'childrens',
    schema: {
      model: {
        id: 'id',
        hasChildren: 'isParent'
      }
    }
  }
}

// 可用的篩選條件：FilterUnitAdd.vue 元件
const keyWithOptions = [
  { key: '作業系統', options: ['Unknown', 'IOS', 'Windows', 'MacOS', 'Linux'] },
  { key: '區域群組', options: [] }
]

// 起始的篩選組合：FilterUnitDeletable.vue 元件
const initialFilterUnitList = [
  {
    id: 0,
    key: '作業系統',
    operator: '==',
    value: 'Unknown',
    options: ['Unknown', 'IOS', 'Windows', 'MacOS', 'Linux'] // 值沒有選項，會渲染文字輸入框
  }
]

export { treeViewData, treeViewSchema, keyWithOptions, initialFilterUnitList }
