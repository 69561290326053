// 符規標準列表

// 可用的篩選條件：FilterUnitAdd.vue 元件
const keyWithOptions = [
  { key: '名稱', options: [] },
  { key: '系統名稱', options: [] }
]

// 起始的篩選組合：FilterUnitDeletable.vue 元件
const initialFilterUnitList = []

export { keyWithOptions, initialFilterUnitList }
