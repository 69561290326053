<template>
  <td class="serial-port-info-cell" :data-id="dataItem.Id">
    <div
      class="operation-btn"
      :class="{ 'is-active': showPopup }"
      ref="popupBtnRef"
      @click="togglePopup"
    >
      <SvgIcon icon="tree" />
    </div>
    <Popup
      :show="showPopup"
      :anchor="'popupBtnRef'"
      :popup-class="'popup'"
      :animate="false"
    >
      <div
        class="popup-row mb-3 text-center"
        style="font-size: 18px; font-weight: 500;"
      >
        套用範圍
      </div>
      <div
        class="popup-row mb-1"
        v-for="(site, idx) in dataItem.ApplyScope.site"
        :key="idx"
      >
        <SvgIcon icon="tree-site" class="mr-1" />
        {{ site }}
      </div>
      <div
        class="popup-row mb-1"
        v-for="(network, idx) in dataItem.ApplyScope.network"
        :key="idx"
      >
        <SvgIcon icon="tree-network" class="mr-1" />
        {{ network }}
      </div>
      <div
        class="popup-row mb-1"
        v-for="(ip, idx) in dataItem.ApplyScope.ip"
        :key="idx"
      >
        <SvgIcon icon="tree-ip" class="mr-1" />
        {{ ip }}
      </div>
    </Popup>
  </td>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import useToggle from '@/composable/useToggle'
import { Popup } from '@progress/kendo-vue-popup'

export default defineComponent({
  components: {
    Popup
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    const { show: showPopup, toggle: togglePopup } = useToggle()
    const popupBtnRef = ref('')
    return {
      showPopup,
      togglePopup,
      popupBtnRef
    }
  }
})
</script>

<style scoped lang="scss">
.serial-port-info-cell {
  text-align: center;
}
</style>
