<template>
  <td class="precheck-status-cell" :data-id="dataItem.Id">
    <KendoTooltip
      id="tooltip"
      :title="dataItem.Status ? '停用未符規封鎖' : '啟用未符規封鎖'"
      :position="'left'"
    >
      <div class="setting-btn" :class="{ 'is-active': dataItem.Status }">
        <SvgIcon icon="not-allow" />
      </div>
    </KendoTooltip>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped>
.precheck-status-cell {
  text-align: right;
}
</style>
