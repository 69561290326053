
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import '@progress/kendo-ui/js/kendo.timepicker'
import { TimePicker } from '@progress/kendo-dateinputs-vue-wrapper'
import '@progress/kendo-ui/js/kendo.switch'
import { KendoSwitch } from '@progress/kendo-inputs-vue-wrapper'

export default defineComponent({
  components: {
    DropDownList,
    FeildInputGroup,
    TimePicker,
    KendoSwitch
  },
  setup() {
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      submitForm
    }
  }
})
