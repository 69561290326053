<template>
  <div class="form-wrapper">
    <form>
      <div class="d-flex flex-row">
        <div style="max-width: 255px; min-width: 255px; margin-left: 40px;">
          <div
            class="feild-row"
            style="border-top: 1px solid #393D46; border-bottom: 1px solid #393D46;"
          >
            <p class="feild-name" style="min-width: 194px;">自動排程</p>
            <p class="feild-value">
              <KendoSwitch :checked="true"></KendoSwitch>
            </p>
          </div>
        </div>
        <div>
          <div class="single-feild-group">
            <label class="feild-name">檢查頻率</label>
            <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
              <DropDownList
                style="width: 215px;"
                :data-source="[
                  { name: '每隔一天', value: 0 },
                  { name: '每隔三天', value: 1 }
                ]"
                :data-text-field="'name'"
                :data-value-field="'value'"
              />
            </FeildInputGroup>
          </div>
          <div class="single-feild-group">
            <label class="feild-name">執行時間</label>
            <TimePicker
              :style="{ height: '35px', width: '215px' }"
              :value="new Date()"
            />
          </div>
          <div class="single-feild-group">
            <label class="feild-name">設備連續幾天上線</label>
            <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
              <DropDownList
                style="width: 215px;"
                :data-source="[
                  { name: '10', value: 10 },
                  { name: '9', value: 9 }
                ]"
                :data-text-field="'name'"
                :data-value-field="'value'"
              />
            </FeildInputGroup>
          </div>
          <div class="single-feild-group mb-5">
            <label class="feild-name">類型</label>
            <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
              <DropDownList
                style="width: 215px;"
                :data-source="[{ name: '僅發送通知信', value: 0 }]"
                :data-text-field="'name'"
                :data-value-field="'value'"
              />
            </FeildInputGroup>
          </div>
        </div>
      </div>
      <div class="footer-group">
        <button
          type="submit"
          class="btn btn-primary form-btn mr-2"
          @click.prevent="submitForm"
        >
          完成排程
        </button>
        <button class="btn form-btn-outline" @click.prevent="$emit('close')">
          取消
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import '@progress/kendo-ui/js/kendo.timepicker'
import { TimePicker } from '@progress/kendo-dateinputs-vue-wrapper'
import '@progress/kendo-ui/js/kendo.switch'
import { KendoSwitch } from '@progress/kendo-inputs-vue-wrapper'

export default defineComponent({
  components: {
    DropDownList,
    FeildInputGroup,
    TimePicker,
    KendoSwitch
  },
  setup() {
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      submitForm
    }
  }
})
</script>

<style scoped></style>
