
import { defineComponent } from 'vue'
import Badge from '@/components/Badge/Badge.vue'

export default defineComponent({
  components: {
    Badge
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
