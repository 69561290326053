<template>
  <span class="base-badge" :class="[type]">
    <slot />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    type: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
