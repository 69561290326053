<template>
  <td class="enable-time-cell" :data-id="dataItem.Id">
    {{ dataItem.EnableTime }}
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped>
.enable-time-cell {
  text-align: center;
}
</style>
