<template>
  <div class="lable-item"><slot /><SvgIcon icon="cross" /></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
