
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    type: String
  },
  setup() {
    return {}
  }
})
