<template>
  <h2 class="pre-check-title">符規標準</h2>

  <Tabs>
    <Tab pathName="PreCheckList" title="符規標準列表" />
    <Tab pathName="AgainstRules" title="未符規設備列表" />
    <Tab pathName="PathSource" title="更新檔來源" />
  </Tabs>

  <div class="main-list-view site-list-view">
    <!-- 列表容器 -->
    <div class="main-list-container">
      <!-- 上：控制列容器 -->
      <ControlWrapper>
        <ControlButton
          iconName="plus"
          text="新增符規標準"
          @click="toggleNewPreCheck"
        />
        <ControlButton
          iconName="time"
          text="未關機設備封鎖排程"
          @click="toggleBlockSchedule"
        />
      </ControlWrapper>
      <!-- 中：篩選列容器 -->
      <FilterWrapper>
        <FilterTextInput
          :placeholder="'查詢關鍵字'"
          v-model.trim="textInput"
          @submitFilter="submitFilter"
        />
        <FilterUnitDeletable
          v-for="filterUnit in filterUnitList"
          :filterUnit="filterUnit"
          :key="filterUnit.key"
          @updateFilterUnit="updateFilterUnit"
          @deleteFilterUnit="deleteFilterUnit"
        />
        <FilterUnitAdd
          :keyWithOptions="keyWithOptions"
          @addFilterUnit="addFilterUnit"
        />
        <div class="spacer"></div>
      </FilterWrapper>
      <!-- 下：表格容器 -->
      <GridWrapper>
        <GridPageInfo :skip="skip" :take="take" :total="total" />
        <Grid
          :style="{ height: 'calc(100vh - 300px)' }"
          :columns="columns"
          :data-items="result"
          :pageable="pageable"
          :skip="skip"
          :take="take"
          :total="total"
          @pagechange="onPageChange"
          :scrollable="'true'"
          :sortable="false"
        ></Grid>
      </GridWrapper>
    </div>
  </div>

  <!-- 隱藏：Dialog 容器 -->
  <div class="dialog-container">
    <Dialog
      v-if="showNewPreCheck"
      :title="'新增符規標準'"
      :width="1100"
      :height="700"
      @close="toggleNewPreCheck"
    >
      <NewPreCheckForm @close="toggleNewPreCheck" />
    </Dialog>

    <Dialog
      v-if="showBlockSchedule"
      :title="'未關機設備封鎖排程'"
      :width="930"
      :height="535"
      @close="toggleBlockSchedule"
    >
      <SetBlockScheduleForm @close="toggleBlockSchedule" />
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import usGridInPreCheckList from '@/composable/useGridInPreCheckList'
import '@progress/kendo-ui/js/kendo.dialog'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import NewPreCheckForm from '@/components/Form/PreCheckListPage/NewPreCheckForm.vue'
import SetBlockScheduleForm from '@/components/Form/PreCheckListPage/SetBlockScheduleForm.vue'

import { keyWithOptions, initialFilterUnitList } from '@/mock/data/preCheckList'

export default defineComponent({
  components: {
    Tabs,
    Tab,
    ControlWrapper,
    ControlButton,
    FilterWrapper,
    FilterTextInput,
    FilterUnitDeletable,
    FilterUnitAdd,
    GridWrapper,
    Grid,
    GridPageInfo,
    Dialog,
    NewPreCheckForm,
    SetBlockScheduleForm
  },
  setup() {
    // 篩選相關
    // 文字輸入
    const textInput = ref('')

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // 提交篩選
    const submitFilter = () => {
      // FIXME: 這裡提供了文字輸入、勾選、自訂篩選組的值，可以做後續組裡
      console.log('textInput: ' + textInput.value)
      console.log('filterUnitList: ' + filterUnitList.value)
    }

    // Grid 相關：
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = usGridInPreCheckList()

    onMounted(() => {
      dataItems.value = createRandomData(16) as never[]
    })

    const { show: showNewPreCheck, toggle: toggleNewPreCheck } = useToggle()
    const { show: showBlockSchedule, toggle: toggleBlockSchedule } = useToggle()
    return {
      // 篩選相關：文字輸入
      textInput,
      submitFilter,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler,
      // Dialog 相關
      showNewPreCheck,
      toggleNewPreCheck,
      showBlockSchedule,
      toggleBlockSchedule
    }
  }
})
</script>

<style scoped></style>
