<template>
  <td class="precheck-operation-cell" :data-id="dataItem.Id">
    <KendoTooltip id="tooltip" title="預覽未符規設備" :position="'left'">
      <div class="operation-btn">
        <SvgIcon icon="preview" />
      </div>
    </KendoTooltip>
    <KendoTooltip id="tooltip" title="編輯" :position="'left'">
      <div class="operation-btn">
        <SvgIcon icon="edit" />
      </div>
    </KendoTooltip>
    <KendoTooltip
      id="tooltip"
      :position="'left'"
      :title="dataItem.Operation.isDeletable ? `刪除` : `禁止刪除`"
    >
      <div
        class="operation-btn"
        :class="{ 'is-disabled': !dataItem.Operation.isDeletable }"
      >
        <SvgIcon icon="garbage" />
      </div>
    </KendoTooltip>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped>
.precheck-operation-cell {
  text-align: center;
}
</style>
