<template>
  <div class="site-operation-cell-header">{{ title }}</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: String,
    title: String,
    sortable: [Boolean, Object]
  },
  setup() {
    return {}
  }
})
</script>

<style scoped lang="scss">
.site-operation-cell-header {
  text-align: right;
  padding-right: 8px;
}
</style>
