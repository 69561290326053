
import { defineComponent, ref, onMounted } from 'vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import usGridInPreCheckList from '@/composable/useGridInPreCheckList'
import '@progress/kendo-ui/js/kendo.dialog'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import NewPreCheckForm from '@/components/Form/PreCheckListPage/NewPreCheckForm.vue'
import SetBlockScheduleForm from '@/components/Form/PreCheckListPage/SetBlockScheduleForm.vue'

import { keyWithOptions, initialFilterUnitList } from '@/mock/data/preCheckList'

export default defineComponent({
  components: {
    Tabs,
    Tab,
    ControlWrapper,
    ControlButton,
    FilterWrapper,
    FilterTextInput,
    FilterUnitDeletable,
    FilterUnitAdd,
    GridWrapper,
    Grid,
    GridPageInfo,
    Dialog,
    NewPreCheckForm,
    SetBlockScheduleForm
  },
  setup() {
    // 篩選相關
    // 文字輸入
    const textInput = ref('')

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // 提交篩選
    const submitFilter = () => {
      // FIXME: 這裡提供了文字輸入、勾選、自訂篩選組的值，可以做後續組裡
      console.log('textInput: ' + textInput.value)
      console.log('filterUnitList: ' + filterUnitList.value)
    }

    // Grid 相關：
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = usGridInPreCheckList()

    onMounted(() => {
      dataItems.value = createRandomData(16) as never[]
    })

    const { show: showNewPreCheck, toggle: toggleNewPreCheck } = useToggle()
    const { show: showBlockSchedule, toggle: toggleBlockSchedule } = useToggle()
    return {
      // 篩選相關：文字輸入
      textInput,
      submitFilter,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler,
      // Dialog 相關
      showNewPreCheck,
      toggleNewPreCheck,
      showBlockSchedule,
      toggleBlockSchedule
    }
  }
})
