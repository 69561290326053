<template>
  <div class="form-wrapper">
    <form>
      <div class="feild-group-title mx-4">
        <h3>符規標準資料</h3>
      </div>
      <div class="single-feild-group pl-5">
        <label class="feild-name">符規標準名稱</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <KendoInput type="text" style="width: 335px;" />
        </FeildInputGroup>
      </div>
      <div class="single-feild-group pl-5">
        <label class="feild-name">套用設備條件</label>
        <div style="width: 335px;">
          <FilterWrapper class="mx-0" style="flex-wrap: wrap; overflow: auto;">
            <FilterUnitDeletable
              v-for="filterUnit in filterUnitList"
              :filterUnit="filterUnit"
              :key="filterUnit.key"
              @updateFilterUnit="updateFilterUnit"
              @deleteFilterUnit="deleteFilterUnit"
            />
            <FilterUnitAdd
              :titleName="'設備條件'"
              :keyWithOptions="keyWithOptions"
              @addFilterUnit="addFilterUnit"
            />
          </FilterWrapper>
        </div>
      </div>
      <div class="single-feild-group pl-5">
        <label class="feild-name">啟用時間</label>
        <DateTimePicker
          :format="'yyyy/MM/dd hh:mm:ss a'"
          :default-value="new Date()"
          style="width: 335px;"
        />
      </div>
      <div class="single-feild-group pl-5 mb-5">
        <label class="feild-name">說明文件連結</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <KendoInput type="text" style="width: 335px;" />
        </FeildInputGroup>
      </div>
      <div class="feild-group-title mx-4">
        <h3>驗證符規來源</h3>
      </div>
      <div class="dragable-list">
        <!-- 符規來源：第ㄧ列 -->
        <div class="dragable-item">
          <SvgIcon icon="drag-handle" />
          <!-- 驗證符規資料來源 -->
          <div class="single-feild-group pl-2">
            <label class="feild-name">驗證符規資料來源</label>
            <div style="width: 250px;">
              <DropDownList
                style="width: 250px;"
                :data-source="[
                  { name: '未安裝 Windows Hotfix', value: 'UN_WINDOWS_HOTFIX' },
                  {
                    name: 'Windows Hotfix',
                    value: 'WINDOWS_HOTFIX'
                  },
                  {
                    name: 'Trend',
                    value: 'TREND'
                  }
                ]"
                :data-text-field="'name'"
                :data-value-field="'value'"
              />
              <div class="text-info d-flex mt-3">
                <SvgIcon icon="warning" class="text-info mr-2" />
                <p>
                  此功能必須佈署 GPO 政策，以取得相關資料，若有疑問請聯絡客服
                </p>
              </div>
            </div>
          </div>
          <!-- 必須安裝的 Hotfix -->
          <div style="width: 370px;" class="pl-4">
            <div class="single-feild-group pl-2">
              <label class="feild-name">必須安裝的 Hotfix</label>
              <div class="from-input-group">
                <KendoInput
                  type="text"
                  style="width: 46px;"
                  value="KB"
                  readonly
                />
                <KendoInput type="text" style="width: 63px;" value="12" />
                <button
                  class="btn btn-primary p-1"
                  type="button"
                  style="width: 35px; height: 35px;"
                >
                  <SvgIcon icon="plus" />
                </button>
              </div>
            </div>
            <!-- KB 標籤 -->
            <div class="lable-list mt-2">
              <Lable>KB 12</Lable>
              <Lable>KB 3</Lable>
            </div>
          </div>
          <SvgIcon icon="garbage" />
        </div>
        <!-- 符規來源：第ㄧ列 -->
        <div class="dragable-item">
          <SvgIcon icon="drag-handle" />
          <!-- 驗證符規資料來源 -->
          <div class="single-feild-group pl-2">
            <label class="feild-name">驗證符規資料來源</label>
            <div style="width: 250px;">
              <DropDownList
                style="width: 250px;"
                :data-source="[
                  { name: '未安裝 Windows Hotfix', value: 'UN_WINDOWS_HOTFIX' },
                  {
                    name: 'Windows Hotfix',
                    value: 'WINDOWS_HOTFIX'
                  },
                  {
                    name: 'Trend',
                    value: 'TREND'
                  }
                ]"
                :data-text-field="'name'"
                :data-value-field="'value'"
              />
              <div class="text-info d-flex mt-3" v-if="false">
                <SvgIcon icon="warning" class="text-info mr-2" />
                <p>
                  此功能必須佈署 GPO 政策，以取得相關資料，若有疑問請聯絡客服
                </p>
              </div>
            </div>
          </div>
          <!-- 防毒版本 -->
          <div style="width: 370px;">
            <div class="single-feild-group pl-2">
              <label class="feild-name"></label>
              <div class="from-input-group">
                <DropDownList
                  style="width: 124px;"
                  :data-source="[
                    { name: '防毒版本', value: 'ANTI_VIRUS_VERSOIN' },
                    {
                      name: '病毒碼',
                      value: 'VIRUS_CODE'
                    },
                    {
                      name: '防毒最後更新時間',
                      value: 'ANTI_VIRUS_LAST_TIME'
                    }
                  ]"
                  :data-text-field="'name'"
                  :data-value-field="'value'"
                />
                <DropDownList
                  style="width: 100px;"
                  :data-source="[
                    { name: '大於等於', value: '>=' },
                    {
                      name: '小於等於',
                      value: '<='
                    },
                    {
                      name: '等於',
                      value: '=='
                    }
                  ]"
                  :data-text-field="'name'"
                  :data-value-field="'value'"
                />
                <KendoInput type="text" style="width: 63px;" value="12" />
                <button
                  class="btn btn-primary p-1"
                  type="button"
                  style="width: 35px; height: 35px;"
                >
                  <SvgIcon icon="plus" />
                </button>
              </div>
            </div>
          </div>
          <SvgIcon icon="garbage" />
        </div>
        <div class="add-btn"><SvgIcon icon="plus" />新增規則</div>
      </div>
      <div class="feild-group-title mx-4">
        <h3>套用範圍</h3>
      </div>
      <div class="treeview-wrapper" style="margin: 40px auto 50px 120px;">
        <TreeView
          ref="treeViewRef"
          :data-source="localDataSource"
          :data-text-field="['name']"
          :template="template"
          :checkboxes="true"
          :checkboxes-check-children="true"
        ></TreeView>
      </div>
      <div class="footer-group">
        <button
          type="submit"
          class="btn btn-primary form-btn mr-2"
          @click.prevent="submitForm"
        >
          完成新增
        </button>
        <button class="btn form-btn-outline" @click.prevent="$emit('close')">
          取消
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw, ref } from 'vue'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import { Input } from '@progress/kendo-vue-inputs'
// import BaseInput from '@/components/Input/BaseInput.vue'
import { DateTimePicker } from '@progress/kendo-vue-dateinputs'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import Lable from '@/components/Lable/Lable.vue'
import '@progress/kendo-ui/js/kendo.treeview'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'
import '@progress/kendo-ui/js/kendo.data'

import {
  treeViewData,
  treeViewSchema,
  keyWithOptions,
  initialFilterUnitList
} from '@/mock/data/newPreCheck'

export default defineComponent({
  components: {
    DropDownList,
    KendoInput: Input,
    // BaseInput,
    DateTimePicker,
    FeildInputGroup,
    FilterWrapper,
    FilterUnitDeletable,
    FilterUnitAdd,
    Lable,
    TreeView
  },
  setup() {
    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    const submitForm = () => {
      // FIXME:
    }

    // TreeView 相關
    const template =
      '# for (i = 0; i < item.icons.length; i++ ) { #' +
      '<span class="tree-icon ' +
      '#: item.icons[i] #' +
      '"></span>' +
      '# } #' +
      '#: item.name #' +
      '<span class="child-count">' +
      '#: Number(item.count).toLocaleString() #' + // 1000 -> "1,000"
      '</span>'

    const localDataSource = markRaw(
      // eslint-disable-next-line
        new kendo.data.HierarchicalDataSource({
        data: treeViewData,
        schema: treeViewSchema
      })
    )
    // treeview template refs
    const treeViewRef = ref(null)

    return {
      // TreeView 相關
      template,
      localDataSource,
      treeViewRef,
      // 篩選相關
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      submitForm
    }
  }
})
</script>

<style scoped></style>
